//-------------------------------------------------------------------//
// Module: ColumnDefs                                                //
// SubModule: UsersColumnDefs                                        //
// Tasks: 1) Returns an Array of Users Column Defs                   //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//
import { baseURL } from "../../configuration/constants";
import axios from "axios";
import {
  getHeaders

} from "../helper";
export function getUsersColumnDefsArray(
  converted_role_options_json,
  all_role_ids
) {
  let editablePermission = localStorage.getItem("userManagement");
  return [
    {
      headerName: "Full Name",
      field: "full_name",
      editable: editablePermission ? true : false,
      filter: "agTextColumnFilter",
      pinned: "left",
      width: 350,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true
    },
    {
      headerName: "Username",
      field: "username",
      editable: editablePermission ? true : false,
      filter: "agTextColumnFilter",
      width: 300
    },
    {
      headerName: "Role",
      field: "role_id",
      editable: editablePermission ? true : false,
      filter: "agTextColumnFilter",
      width: 250,

      cellEditor: "agSelectCellEditor",
      refData: converted_role_options_json,
      cellEditorParams: {
        values: all_role_ids
      }
    },
    {
      headerName: "Inventory Alert ",
      field: "inventory_alert",
      filter: "agTextColumnFilter",
      width: 180,
      editable: editablePermission ? true : false,
      cellRenderer: params => {
        var input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.disabled = editablePermission ? false : true
        input.addEventListener('click', function (event) {
          let r = window.confirm("Are you sure you want to update?");

          if (r === false) {
            if (params.data.inventory_alert == 0) {
              input.checked = false;
            }
            if (params.data.inventory_alert == 1) {
              input.checked = true;
            }
            return false;

          } else {
            if (input.checked === false) {
              params.value = 0;
            }
            else if (input.checked === true) {
              params.value = 1;
            }
            params.data.inventory_alert = params.value;
            let updateData = updateInventoryAlert(params);
          }
        });

        return input;
      },
    },
    {
      headerName: "Hide Entries",
      field: "hide_entry",
      filter: "agTextColumnFilter",
      width: 180,
      editable: editablePermission ? true : false,
      cellRenderer: params => {
        var input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.disabled = editablePermission ? false : true
        input.addEventListener('click', function (event) {
          let r = window.confirm("Are you sure you want to update?");

          if (r === false) {
            if (params.data.hide_entry == 0) {
              input.checked = false;
            }
            if (params.data.hide_entry == 1) {
              input.checked = true;
            }
            return false;

          } else {
            if (input.checked === false) {
              params.value = 0;
            }
            else if (input.checked === true) {
              params.value = 1;
            }
            params.data.hide_entry = params.value;
            let updateData = updateHideEntries(params);
          }
        });

        return input;
      },
    },
    {
      headerName: "PIN",
      field: "pin",
      filter: "agTextColumnFilter",
      width: 180,
      editable: editablePermission ? true : false,
      cellRenderer: params => {
        var input = document.createElement('input');
        input.type = "text";
        input.value = params.value || "";
        input.disabled = editablePermission ? false : true
        input.style.border = "none";
        input.style.outline = "none";
        input.addEventListener('submit', function (event) {
          let r = window.confirm("Are you sure you want to update?");

          if (r === false) {
            params.data.pin = params.value;
            return false;

          } else {
            params.data.pin = event.target.value;
            updatePIN(params);
          }
        });

        return input;
      },
    },
    {
      headerName: "View MMR/BPR Documents",
      field: "view_mmr_bpr_documents",
      filter: "agTextColumnFilter",
      width: 180,
      editable: editablePermission ? true : false,
      cellRenderer: params => {
        var input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.disabled = editablePermission ? false : true
        input.addEventListener('click', function (event) {
          let r = window.confirm("Are you sure you want to update?");

          if (r === false) {
            if (params.data.view_mmr_bpr_documents == 0) {
              input.checked = false;
            }
            if (params.data.view_mmr_bpr_documents == 1) {
              input.checked = true;
            }
            return false;

          } else {
            if (input.checked === false) {
              params.value = 0;
            }
            else if (input.checked === true) {
              params.value = 1;
            }
            params.data.view_mmr_bpr_documents = params.value;
            updateViewMmrBprDocuments(params);
          }
        });

        return input;
      },
    },
  ];
}
function updateInventoryAlert(updateData) {
  let res = updateData.data;

  let id = res.id;

  axios
    .put(
      baseURL +
      "/users/updateInventoryAlert/" +
      id,
      res,
      getHeaders(),
    )
    .then(response => {
      alert(response.data.message);

      window.location.reload(false);

      if (!response.data.success) {
        alert("Updating failed! Please contact Admin.");

      }

    })
    .catch(error => {
      alert("Updating from Display Grid | Error: ", error);
    });


}

function updateHideEntries(updateData) {
  let res = updateData.data;

  let id = res.id;

  axios
    .put(
      baseURL +
      "/users/hideEntryAlert/" +
      id,
      res,
      getHeaders(),
    )
    .then(response => {
      alert(response.data.message);

      window.location.reload(false);

      if (!response.data.success) {
        alert("Updating failed! Please contact Admin.");

      }

    })
    .catch(error => {
      alert("Updating from Display Grid | Error: ", error);
    });


}

function updatePIN(updateData) {
  console.log("updateData", updateData)
  let res = updateData.data;

  let id = res.id;

  axios
    .put(
      baseURL +
      "/users/updateUserPinNew/" +
      id,
      res,
      getHeaders(),
    )
    .then(response => {
      alert(response.data.message);

      window.location.reload(false);

      if (!response.data.success) {
        alert("Updating failed! Please contact Admin.");

      }

    })
    .catch(error => {
      alert("Updating from Display Grid | Error: ", error);
    });


}

function updateViewMmrBprDocuments(updateData) {
  let res = updateData.data;

  let id = res.id;

  axios
    .put(
      baseURL +
      "/users/hide_MMR_BPR_Doc/" +
      id,
      res,
      getHeaders(),
    )
    .then(response => {
      alert(response.data.message);

      window.location.reload(false);

      if (!response.data.success) {
        alert("Updating failed! Please contact Admin.");

      }

    })
    .catch(error => {
      console.log("error", error)
      alert("Updating from Display Grid | Error: ", error);
    });


}
