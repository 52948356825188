
import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import _ from "lodash";

import { Message, Header, Segment, Grid, Dropdown, Button, Icon, Popup } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { baseURL, page_size_options } from "../../../configuration/constants";
import { onGridReady, getHeaders, onPageSizeChanged, resetSort, resetFilter, focusFirstElement, digitsAfterPoint, getUserRole, simplifyModulePermissionData, validation_clinical_product_type, getSessionStoredJsonItem } from "../../helper";
import CustomConfirm from "../../custom_elements/CustomConfirm";
import FormullaInfo from "./FormullaInfo";
import IngredientBarcodeTable from "./IngredientBarcodeTable";
import FlourishPrimeInfo from "./FlourishPrimeInfo";
import CustomLoader from "../../custom_elements/CustomLoader";
import Config from "../../../configuration/config";
import Doc from "../../formulation_mod/DocService";
import MultiPdfDownload from "./pdfPractise";

import StartFormulationModals from "./start_formulation/StartFormulationModal";
import "../../pdf.css";
import ReactFileReader from 'react-file-reader';
import ReformulationForm from "../../formulation_mod/ReformulationForm";

let api, column_api;
let typeParams = "";
let lowerCase = "";
let array1 = [];
let alot = [];
var reformulation_logs = [];
var reformulation_logs_exists = null;

let view_mmr_bpr_documents = getSessionStoredJsonItem("user") ? getSessionStoredJsonItem("user").view_mmr_bpr_documents : 0;

const Payment = [
    { type: "" },
    { type: "Paid" },
    { type: "Free" }
]

const options = [
    { value: 0 },
    { value: 1 },
    { value: "powder" }
];
var loading = "";


class SampleFormullaIdTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reformulation_logs: [],
            reformulation_logs_exists: null,
            msg: "",
            qa_notes: "",
            qa_accepted_by: "",
            qa_verified_at: "",
            reformulation_notes: "",
            success: "",
            blankArray: [],
            show: false,
            is_page_loading_did_mount: true,
            deleteMsg: "",
            calculation_unit: "",
            username: "",
            organism: {},
            pdfs: true,
            pdfId: "",
            pdfSampleId: "",
            pdfFormullaId: "",
            is_page_loading: false,
            is_special_button_disabled: true,
            defaultValue: false,
            probiotic_blend: "",
            currentAge: "",
            qc_weight: "",
            pre_names: "",
            pro_names: "",
            downloadData: "",
            calories: "",
            defaultColDef: {
                resizable: true,
                sortable: true,
                enableCellChangeFlash: true,
            },
            samples: [],
            samplesInventory: [],
            id: "",
            number_of_months: "",
            is_prime: "",
            admin_notes: "",
            operator_notes: "",
            payment_type: "empty",
            date_created: "",
            date_made: "",
            updated_at: "",
            is_confirm_open: false,
            is_completed: "",
            total_final_concentration: "",
            total_final_amount_added: "",
            flourish_fill_qc_weight: "",
            filler_organism_barcode: "",
            flourish_powder_remaining: "",
            flourish_total_capsules_made: "",
            flourish_capsule_size: "",
            prime_capsule_size: "",
            prime_fill_qc_weight: "",
            prime_filler_amount_added: "",
            prime_flourish_amount_added: "",
            prime_powder_remaining: "",
            prime_total_amount_added: "",
            prime_total_capsules_made: "",
            refill_type: "",
            disabledPermission: "",
            deletePermission: 0,
            dashboard: 0,
            start_formulation: false,
            sample_id: "",
            formula_id: "",

            disabledPermissionOnEdit: "",
            editPermission: 0,
            dashboardEdit: 0,
            start_refill: false,
            reformulation_notes: '',
            columnDefs: [
                {
                    field: "id",
                    checkboxSelection: true,
                    width: 28,
                    cellStyle: function (params) {
                        if (params.data.is_completed === "No") {
                            return { display: 'none' };
                        }
                    }
                },
                {
                    headerName: "Sample ID",
                    field: "sample_id",
                    filter: "agTextColumnFilter",
                    width: 150,
                },
                {
                    headerName: "Formula ID",
                    field: "formula_id",
                    width: 160,
                    filter: "agTextColumnFilter",
                },
                {
                    headerName: "Product Type",
                    field: "product_type",
                    width: 160,
                    filter: true,
                    cellEditor: "agSelectCellEditor",
                },
                {
                    headerName: "Type",
                    field: "type",
                    width: 150,
                    filter: "agTextColumnFilter",
                    cellRenderer: (params) => {
                        if (params.value === "refill") {
                            return (
                                `
                                <div>
                                Refill
                                </div>
                                `
                            )
                        }
                        else {
                            return (
                                `
                                <div>
                                ${params.value.charAt(0).toUpperCase() + params.value.slice(1)}
                                </div>
                                `
                            )
                        }
                    }
                },
                {
                    headerName: "Is Completed?",
                    field: "is_completed",
                    filter: true,
                    cellEditor: "agSelectCellEditor",
                    cellEditorParams: {
                        values: ["Yes", "No"]
                    },
                    refData: { "No": "No", "Yes": "Yes" },
                    cellEditorParams: {
                        values: ["No", "Yes"]
                    },
                    width: 130
                },
                {
                    headerName: "Reformulation Source",
                    field: "reformulation_request_source",
                    width: 180,
                    sizeColumnToFit: true,
                    filter: "agTextColumnFilter",
                    sortable: true
                },
                {
                    headerName: "QA Reviewed By",
                    field: "qa_reviewed_by.full_name",
                    width: 180,
                    sizeColumnToFit: true,
                    filter: "agTextColumnFilter",
                    sortable: true
                },
                {
                    headerName: "QA Reviewed Date and Time",
                    field: "qa_reviewed_at",
                    width: 180,
                    sizeColumnToFit: true,
                    filter: "agTextColumnFilter",
                    sortable: true
                },
                {
                    headerName: "Refill Creation?",
                    field: "refill_type",
                    width: 140,
                    filter: "agTextColumnFilter",
                },
                {
                    headerName: "Is Prebiotic?",
                    field: "is_prebiotic",
                    cellRenderer: (params) => {
                        if (params.data.is_prebiotic === "No" && validation_clinical_product_type.indexOf(params.data.product_type) === -1) {
                            return (
                                `<a href="/create_formulation?sample_id=${params.data.sample_id}">
                                  <button style="color: #fff;background-color: #0e972e;border-color: #0e972e;cursor: pointer;">Add Prebiotics</button>
                                </a>`
                            );
                            /*  return  (`<a href="/create_formulation?sample_id=`+params.data.sample_id+`>
                             <button>Probiotic</button>
                           </a>`) */
                        } else {
                            return (`<div></div>`)
                        }
                    },
                    width: 140,
                    filter: "agTextColumnFilter",
                },
                {
                    headerName: "View MMR",
                    field: "is_qa_verified",
                    hide: view_mmr_bpr_documents != undefined ? view_mmr_bpr_documents == 0 : true,
                    cellRenderer: (params) => {
                        if (params.data.is_qa_verified == 1) {
                            const button = document.createElement("button");
                            button.innerText = "View MMR";
                            button.style.color = "#fff";
                            button.style.backgroundColor = "#0e972e";
                            button.style.cursor = "pointer";

                            button.addEventListener("click", () => {
                                this.getMMRBPRDocuments(params.data.formula_id, "MMR")
                            });

                            return button;
                        } else {
                            return (`<div></div>`)
                        }
                    },
                    width: 140,
                    filter: "agTextColumnFilter",
                },
                {
                    headerName: "View BPR",
                    field: "is_completed",
                    hide: view_mmr_bpr_documents != undefined ? view_mmr_bpr_documents == 0 : true,
                    cellRenderer: (params) => {
                        if (params.data.is_completed === "Yes") {
                            const button = document.createElement("button");
                            button.innerText = "View BPR";
                            button.style.color = "#fff";
                            button.style.backgroundColor = "#0e972e";
                            button.style.cursor = "pointer";

                            button.addEventListener("click", () => {
                                this.getMMRBPRDocuments(params.data.formula_id, "BPR")
                            });

                            return button;
                        } else {
                            return (`<div></div>`)
                        }
                    },
                    width: 140,
                    filter: "agTextColumnFilter",
                },

            ]
        };
        this.disableClickSelectionRenderers = ['rowActionRenderer'];
        this.bodyRef = React.createRef();
        this.bodyRef.current = [];
    }

    componentDidMount = () => {
        document.title = Config.formulations.title.display;
        this.getSamples();
        this.getUserPermission()
    }

    getSamples = () => {
        axios
            .get(baseURL + "/formula_details", getHeaders())
            .then(res => {
                let data = res.data;
                this.setState({
                    samples: data.reverse(),
                    is_page_loading_did_mount: false
                })
            })
    }

    getUserPermission = () => {
        let Role = getUserRole();
        let login = localStorage.getItem('login_success');
        if (login == 1) {
            axios
                .get(baseURL + "/users/PermissionDetails", getHeaders())
                .then(response => {
                    let permissionData = null;

                    permissionData = simplifyModulePermissionData(response.data);
                    permissionData = permissionData.filter(data => {
                        if (data.role_name === Role) {
                            return data;
                        }
                    })

                    let dashboard = 0;
                    let disabledPermission;
                    let deletePermission = permissionData.forEach(result => {
                        if (result.module_name == "All Formulations" && result.delete_permission === 1) {
                            dashboard = 1;
                            disabledPermission = false;
                        }
                    })
                    if (dashboard == 0) {
                        deletePermission = 0;
                        disabledPermission = true;
                    }
                    else {

                        deletePermission = 1;
                    }

                    let dashboardEdit = 0;
                    let disabledPermissionOnEdit;
                    let editPermission = permissionData.forEach(result => {
                        if (result.module_name == "All Formulations" && result.edit_permission === 1) {
                            dashboardEdit = 1;
                            disabledPermissionOnEdit = false;
                        }
                    })
                    if (dashboardEdit == 0) {
                        editPermission = 0;
                        disabledPermissionOnEdit = true;
                    }
                    else {

                        editPermission = 1;
                    }

                    this.setState({
                        deletePermission,
                        dashboard,
                        disabledPermission,
                        editPermission,
                        dashboardEdit,
                        disabledPermissionOnEdit

                    });
                })
                .catch(error => {
                    alert("Fetching Display Grid | Error: ", error);
                });
        }
    }

    change = (value) => {
        this.setState({
            flourish_capsule_size: value,
            prime_capsule_size: value
        })
    }

    onRowClicked = (value) => {

        this.setState({
            start_formulation: (value.data.is_completed !== "No" && value.data.type !== "premade") ? true : false,
            sample_id: value.data.sample_id,
            formula_id: value.data.formula_id,
            formulation_id: value.data.id,
            formula_type: value.data.formula_type
        }, () => { });

        // check reformaulation logs exists or not 
        axios.get(baseURL + "/getrefillQueueData/" + value.data.id + "?type=refill_queue", getHeaders())
            .then(res => {
                console.log(res.data[0].qa_notes);
                this.setState({ qa_notes: (res.data[0].qa_notes !== null) ? res.data[0].qa_notes : "" })
                if (res.data[0].reformulation_logs_exist !== null) {
                    this.setState({
                        reformulation_logs: res.data[0].reformulation_logs,
                        reformulation_logs_exists: res.data[0].reformulation_logs_exist
                    })
                }
                else {
                    this.setState({
                        reformulation_logs_exists: null,
                        reformulation_logs: []
                    })


                }
            });

        if (value.data.is_completed == "No" || value.data.type === "premade") {
            this.setState({
                id: value.data.id,
                refill_type: value.data.refill_type,
            })

        }
        this.setState({ pdfs: (value.data.is_completed !== "Yes") ? true : false })

        axios
            .get(baseURL + "/formula_info/" + value.data.id, getHeaders())
            .then(res => {
                let data = res.data;

                let a = data[0].date_created.split(" ")[0].replace(/-/g, '/');
                let b = a.split("/")
                let createdDate = b[1] + "/" + b[2] + "/" + b[0];

                let x = data[0].date_made === null ? "" : data[0].date_made.split(" ")[0].replace(/-/g, '/');
                let y = x === "" ? "" : x.split("/")
                let DateMade = y === "" ? "" : y[1] + "/" + y[2] + "/" + y[0];


                let c = data[0].updated_at.split(" ")[0].replace(/-/g, '/');
                let z = c.split("/")
                let updatedAt = z[1] + "/" + z[2] + "/" + z[0] + " " + data[0].updated_at.split(" ")[1];
                console.log(data[0])
                this.setState({
                    number_of_months: data[0].number_of_months,
                    admin_notes: data[0].admin_notes,
                    operator_notes: data[0].operator_notes,
                    qa_notes: data[0].qa_notes,
                    qa_accepted_by: data[0].qa_verified_by,
                    qa_verified_at: data[0].qa_verified_at,
                    payment_type: data[0].payment_type === "" ? "" : (data[0].payment_type === "paid" ? "Paid" : (data[0].payment_type === "free" ? "Free" : "")),
                    is_prime: data[0].is_prime === "no" ? "No" : "Yes",
                    date_created: createdDate,
                    date_made: value.data.is_completed === 0 ? "" : DateMade,
                    updated_at: updatedAt,
                    username: data[0].username === "" || null ? "" : data[0].username,
                    // qa_accepted_by:data[0].
                    is_completed: value.data.is_completed,
                    success: value.data.success,
                    reformulation_notes: (data[0].reformulation_notes !== null) ? data[0].reformulation_notes : ""
                })
            })

        axios
            .get(baseURL + "/formula_inventory/" + value.data.id, getHeaders())
            .then(res => {
                let data = res.data;
                this.setState({ samplesInventory: data })
            })
        axios
            .get(baseURL + "/formula_total_info/" + value.data.id, getHeaders())
            .then(res => {
                let data = res.data;
                if (data.length > 0) {
                    this.setState({
                        total_final_concentration: data[0].total_final_concentration === null ? "" : data[0].total_final_concentration,
                        total_final_amount_added: data[0].total_final_amount_added === null ? "" : data[0].total_final_amount_added,
                        flourish_fill_qc_weight: data[0].flourish_fill_qc_weight === null ? "" : Math.floor(data[0].flourish_fill_qc_weight * 1000) / 1000,
                        filler_organism_barcode: data[0].filler_organism_barcode === null ? "" : data[0].filler_organism_barcode,
                        flourish_powder_remaining: data[0].flourish_powder_remaining === null ? "" : Math.floor(data[0].flourish_powder_remaining * 1000) / 1000,
                        flourish_total_capsules_made: data[0].flourish_total_capsules_made === null ? "" : Math.floor(data[0].flourish_total_capsules_made * 1000) / 1000,
                        prime_capsule_size: data[0].prime_capsule_size === null ? "" : data[0].prime_capsule_size,
                        prime_fill_qc_weight: data[0].prime_fill_qc_weight == null ? "" : Math.floor(data[0].prime_fill_qc_weight * 1000) / 1000,
                        prime_filler_amount_added: data[0].prime_filler_amount_added === null ? "" : data[0].prime_filler_amount_added,
                        prime_flourish_amount_added: data[0].prime_flourish_amount_added === null ? "" : data[0].prime_flourish_amount_added,
                        prime_powder_remaining: data[0].prime_powder_remaining === null ? "" : Math.floor(data[0].prime_powder_remaining * 1000) / 1000,
                        prime_total_capsules_made: data[0].prime_total_capsules_made === null ? "" : Math.floor(data[0].prime_total_capsules_made * 1000) / 1000,
                        prime_total_amount_added: data[0].prime_total_amount_added === null ? "" : data[0].prime_total_amount_added,
                        flourish_capsule_size: data[0].flourish_capsule_size === null ? "" : data[0].flourish_capsule_size
                    })
                }

            })



    }


    onRowSelected = (value) => {

        if (value.node.selected == false) {
            array1.filter((elem, index) => {
                var index = array1.indexOf(value.data);
                if (index > -1) {
                    array1.splice(index, 1);
                }
            })
        } else {
            array1.push(value.data);

        }

        if (array1.length == 0) {
            this.setState({
                pdfs: true,
            })

        }
    }

    onDownloadPdf = () => {
        this.setState({
            // is_page_loading: true,
        })
        axios
            .get(baseURL + "/pdf-data/" + this.state.pdfId + "/" + this.state.pdfSampleId, getHeaders())
            .then(res => {
                this.setState({
                    // is_page_loading: false,
                    defaultValue: true,
                    downloadData: res.data[0].data.calculation,
                    calculation_unit: res.data[0],
                    calories: res.data[0].data.calories[0].total_value,
                    pre_names: res.data[0].data.pre_names,
                    currentAge: res.data[0].data.currentAge,
                    pro_names: res.data[0].data.pro_names,
                    qc_weight: res.data[0].data.qc_weight,
                    servings_per_container: res.data[0].data.servings_per_container,
                    probiotic_blend: res.data[0].data.probiotic_blend
                })
            })
    }

    onDeleteClick = () => {
        let selectedData = api.getSelectedRows();
        selectedData.map(selectedRow => {
            this.setState({
                deleteMsg: " Are you sure you want to delete the formula " + selectedRow.formula_id + " associated with sample " + selectedRow.sample_id + " ?"
            })
        })
        this.setState({ is_confirm_open: true });
    }

    onCancel = () => {
        this.setState({ is_confirm_open: false });
    };

    onSelectionChanged = () => {
        let selected_rows = api.getSelectedRows();
        if (selected_rows.length > 0)

            this.setState({ is_special_button_disabled: false });
        else this.setState({ is_special_button_disabled: true });
    }


    createAll = (e) => {
        this.setState({
            payment_type: e.target.value
        })
    }
    // Handles 'confirm delete' click.
    onConfirm = () => {
        this.setState({ is_confirm_open: false });
        let selectedData = api.getSelectedRows();
        let ids = [];
        let sampleId;
        ids = "" + selectedData.map(record => record.id);
        sampleId = selectedData.map(record => record.sample_id);
        sampleId.filter((elem => {
            typeParams = elem.split("_", 1);
        }))
        for (let i = 0; i < typeParams.length; i++) {
            let axis = typeParams[i];
            lowerCase = axis.toLowerCase();
        }
        console.log(ids);

        axios
            .get(
                baseURL +
                "/delete_formula/" +
                ids.toString() + "?type=" + lowerCase,
                getHeaders()
            )
            .then(response => {
                alert(response.data.message);
                const interval = setInterval(() => {
                    window.location.reload(false);
                }
                    , 1000);

            })
            .catch(error => {
                alert("Deleting from Display Grid | Error: ", error);
            });


        api.updateRowData({ remove: selectedData });
    };

    onSubmitsupplement = () => {
        let res = _.pick(this.state, [
            "number_of_months",
            "payment_type",
            "admin_notes",
            "operator_notes",
            "reformulation_notes",
            "id"
        ]);
        res.payment_type = res.payment_type === "" ? "empty" : (res.payment_type === "Paid" ? "paid" : (res.payment_type === "Free" ? "free" : ""))
        axios
            .put(baseURL + "/formulations/" + this.state.id, res, getHeaders())
            .then(response => {
                toast.success("Successfully updated the formulation");
                focusFirstElement();
                const interval = setInterval(() => {
                    window.location.reload(false);
                }
                    , 1000);
            })
            .catch(error => {
            });
    }

    onInputChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
        digitsAfterPoint()
    }

    onSubmitsupplementBelow = () => {
        let res = _.pick(this.state, [
            "total_final_concentration",
            "total_final_amount_added",
            "flourish_fill_qc_weight",
            "flourish_capsule_size",
            "flourish_total_capsules_made",
            "flourish_powder_remaining",

            "prime_flourish_amount_added",
            "filler_organism_barcode",
            "prime_filler_amount_added",
            "prime_total_amount_added",
            "prime_fill_qc_weight",
            "prime_capsule_size",
            "prime_total_capsules_made",
            "prime_powder_remaining"
        ]);

        axios
            .put(baseURL + "/formulations/" + this.state.id, res, getHeaders())
            .then(response => {
                toast.success("Successfully updated the formulation");
                focusFirstElement();
                const interval = setInterval(() => {
                    window.location.reload(false);
                }
                    , 1000);
            })
            .catch(error => {
            });
    }

    exportCSV = () => {
        this.setState({
            is_page_loading: true
        })
        axios
            .get(baseURL + "/generate_csv_allformulation", getHeaders())
            .then(response => {
                if (response.data.success === true) {
                    window.open(response.data.url, '_blank');
                    this.setState({
                        is_page_loading: false
                    })

                }
            })
            .catch(error => {

            });
    }


    onDownloadPdfMulti = async (value) => {
        this.setState({ is_page_loading: true });
        var pdfData = [];

        for await (let elem of array1) {
            if (elem.is_completed === "Yes") {
                pdfData.push({ sg_number: elem.sample_id, id: elem.id });
            }
        }

        const res = await axios.post(baseURL + "/generate-label-pdf", pdfData, getHeaders());
        if (res.data.success) {
            window.open(res.data.data, '_blank').focus();
        }
        else {
            toast.error("Something Went Wrong!");
        }

        this.setState({ is_page_loading: false });
    }

    createPdf = (html, id) => {
        Doc.createPdf(html, id);
        this.setState({
            is_page_loading: false,
            show: false,
            blankArray: []
        })
    }

    onCellClicked = (e) => {
        if (e.data.is_completed == "No") {
            if (e.column.colId == "sample_id" || e.column.colId == "formula_id" || e.column.colId == "product_type" || e.column.colId == "type" || e.column.colId == "is_completed"
                || e.column.colId == "id"
            ) {
                array1 = [];
                this.setState({
                    pdfs: true,
                })
                e.node.setSelected(true);
            }
        }

        if (e.data.is_completed == "Yes") {

            if (e.column.colId == "sample_id" || e.column.colId == "formula_id" || e.column.colId == "product_type" || e.column.colId == "type" || e.column.colId == "is_completed" || e.column.colId == "id") {
                // e.node.setSelected(false);
            }
        }
    }

    // why use this beacuse of file reader not accept this function inner side 
    // so create this function use this  
    loadingFalse = () => { this.setState({ is_page_loading: false }); }

    handleCSVFile = files => {
        var csv_type = ["xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "xls", "xml", "xml", "xlam,", "xla", "xlw", "xlr", "csv"];
        var file_name = files[0].name.split(".").pop()
        if (csv_type.indexOf(file_name) === -1) {
            toast.error("Insert Only Csv File ");
            return;
        }
        let scope = this;
        this.setState({ is_page_loading: true });

        var reader = new FileReader();
        reader.onload = async function (e) {
            let base64 = e.srcElement.result;
            let obj = {};
            obj = {
                "base64Media": base64,
                "mediaName": files[0].name,
                "type": files[0].type,
                "size": files[0].size
            }

            axios.post(baseURL + "/generate-csv-bulk-labels", obj, getHeaders()).then((response) => {
                if (response.data.success) {
                    window.open(response.data.data, "_blank");
                    setTimeout(() => {
                        scope.loadingFalse();
                    }, 2000);
                }
                else {
                    toast.error(response.data.data);
                    scope.loadingFalse();

                }
            }).catch((err) => {
                toast.error("Something Went Wrong");
                scope.loadingFalse();
            })

        }
        reader.readAsText(files[0]);
    }

    getMMRBPRDocuments = async (formula_id, type) => {
        this.setState({ is_page_loading: true });
        let params = {
            formula_id: formula_id
        }
        const res = await axios.post(baseURL + "/fetchMMRData", params, getHeaders());
        if (res.status == '200') {
            let result = res.data
            console.log("res", result)
            if (type == "MMR") {
                let mmr_url = result.MMR.data.data
                window.open(mmr_url, '_blank').focus();
            } else {
                let bpr_url = result.BR.data.data
                window.open(bpr_url, '_blank').focus();
            }
        } else {
            toast.error("Something Went Wrong!");
        }

        this.setState({ is_page_loading: false });
    }

    render() {
        if (this.state.defaultValue) {
            return (<Redirect
                to={{
                    pathname: "/pdf",
                    downloadData: this.state.downloadData,
                    calories: this.state.calories,
                    pre_names: this.state.pre_names,
                    qc_weight: this.state.qc_weight,
                    pro_names: this.state.pro_names,
                    currentAge: this.state.currentAge,
                    servings_per_container: this.state.servings_per_container,
                    probiotic_blend: this.state.probiotic_blend,
                    pdfSampleId: this.state.pdfSampleId,
                    pdfFormullaId: this.state.pdfFormullaId

                }} />)
        }
        return (

            <>
                <CustomConfirm
                    is_confirm_open={this.state.is_confirm_open}
                    content={this.state.deleteMsg}
                    header="Delete selected Record(s)"
                    cancel_button_text="Eh! Never Mind."
                    confirm_button_text="DELETE"
                    onCancel={this.onCancel}
                    onConfirm={this.onConfirm}

                />
                {this.state.is_page_loading && <CustomLoader />}
                {this.state.is_page_loading_did_mount && <CustomLoader />}
                <br />
                <Message style={{ marginBottom: "0" }}>
                    <Header as="h3" textAlign="center">
                        {Config.formulations.section_title.display}
                    </Header>
                </Message>
                <Segment
                    compact
                    raised
                    padded
                    style={{ display: "block", marginTop: "0" }}
                >
                    <Grid columns="equal">
                        <div className="filter-flex" >
                            <div className="col-filter">
                                <Dropdown
                                    id="page-size"
                                    placeholder="Page Size"
                                    selection
                                    options={page_size_options}
                                    onChange={newPageSize => onPageSizeChanged(newPageSize, api)}
                                />
                            </div>
                            <div className="col-filter">
                                <Button
                                    basic
                                    icon
                                    onClick={() => resetSort(api)}
                                    labelPosition="left"
                                    style={{ marginLeft: "0" }}
                                >
                                    <Icon name="sort alphabet up" />
                                    Reset Sort(s)
                                </Button>
                            </div>
                            <div className="col-filter">
                                <Button
                                    basic
                                    onClick={() => resetFilter(api)}
                                    icon
                                    labelPosition="left"
                                >
                                    Reset Filter(s)
                                    <Icon name="filter" />
                                </Button>
                            </div>

                            <div className="col-filter">
                                <Button
                                    basic
                                    onClick={this.exportCSV}
                                    icon
                                    labelPosition="left"
                                >
                                    Export as CSV
                                    <Icon name="filter" />
                                </Button>
                            </div>

                            {/* start formulation */}
                            {(this.state.start_formulation) && (
                                <div className="col-filter" >
                                    <StartFormulationModals
                                        sample_id={this.state.sample_id}
                                        formula_id={this.state.formula_id}
                                        formulation_id={this.state.formulation_id}
                                        is_disabled={true}
                                        formula_type={this.state.formula_type}
                                    />
                                </div>
                            )}
                            {/* Download label using csv */}

                            <div className="col-filter" >
                                <ReactFileReader handleFiles={this.handleCSVFile} fileTypes={'.csv'}>
                                    <Popup
                                        trigger={<button className='ui secondary button'>Download Label Using CSV</button>}
                                        content="Upload a CSV file with the formula IDs in the 1st column to download their labels"
                                    />
                                </ReactFileReader>
                            </div>


                            <div className="col-filter"  >
                                <Button
                                    basic
                                    onClick={this.onDownloadPdfMulti}
                                    icon
                                    labelPosition="right"
                                    disabled={this.state.pdfs}
                                >
                                    View Label
                                    <Icon name="download" />
                                </Button>
                            </div>

                            <div className="col-filter" >
                                <Button
                                    basic
                                    negative
                                    onClick={this.onDeleteClick}
                                    icon
                                    labelPosition="right"
                                    disabled={this.state.disabledPermission || this.state.is_special_button_disabled}
                                >
                                    Delete Record(s)
                                    <Icon name="trash" />
                                </Button>
                            </div>


                        </div>
                        <Grid.Row>
                            <Grid.Column>
                                <div
                                    id="myGrid"
                                    className="ag-theme-balham"
                                    ag-grid="gridOptions"
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                >
                                    <AgGridReact
                                        rowHeight="30"
                                        rowSelection="multiple"
                                        onRowClicked={this.onRowClicked}
                                        // onCellClicked={this.onCellClicked}
                                        onGridReady={params => {
                                            api = params.api;
                                            api.showLoadingOverlay();
                                            column_api = params.column_api;
                                            let initial_sort = [{ colId: "id", sort: "desc" }]
                                            onGridReady(
                                                params,
                                                api,
                                                column_api,
                                                initial_sort
                                            );
                                        }}
                                        animateRows={true}
                                        pagination={true}
                                        paginationPageSize={10}
                                        paginationNumberFormatter={params => {
                                            return "[" + params.value.toLocaleString() + "]";
                                        }}
                                        onSelectionChanged={params => this.onSelectionChanged(params)}
                                        columnDefs={this.state.columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        onRowSelected={this.onRowSelected}
                                        floatingFilter={true}
                                        rowData={this.state.samples}
                                        enableCellTextSelection={true}
                                        ensureDomOrder={true}
                                    // suppressRowClickSelection={true}

                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <FlourishPrimeInfo
                                    total_final_concentration={this.state.total_final_concentration}
                                    total_final_amount_added={this.state.total_final_amount_added}
                                    flourish_fill_qc_weight={this.state.flourish_fill_qc_weight}
                                    flourish_total_capsules_made={this.state.flourish_total_capsules_made}
                                    flourish_powder_remaining={this.state.flourish_powder_remaining}
                                    flourish_capsule_size={this.state.flourish_capsule_size}

                                    prime_powder_remaining={this.state.prime_powder_remaining}
                                    prime_total_capsules_made={this.state.prime_total_capsules_made}
                                    prime_fill_qc_weight={this.state.prime_fill_qc_weight}
                                    prime_capsule_size={this.state.prime_capsule_size}
                                    prime_filler_amount_added={this.state.prime_filler_amount_added}
                                    prime_flourish_amount_added={this.state.prime_flourish_amount_added}
                                    options={options}
                                    disabledPermissionOnEdit={this.state.disabledPermissionOnEdit}
                                    success={this.state.success}
                                    is_completed={this.state.is_completed}
                                    filler_organism_barcode={this.state.filler_organism_barcode}
                                    prime_total_amount_added={this.state.prime_total_amount_added}
                                    onInputChange={this.onInputChange}
                                    selectCapsuleSizeFunction={this.change}
                                    onSubmitsupplementBelow={this.onSubmitsupplementBelow} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <IngredientBarcodeTable
                                    samplesInventory={this.state.samplesInventory}
                                    id={this.state.id} />
                            </Grid.Column>
                        </Grid.Row>
                        {this.state.reformulation_logs_exists !== null && this.state.reformulation_logs.length !== 0 && <Grid.Row >
                            <Grid.Column>
                                <ReformulationForm
                                    reformulation_logs={this.state.reformulation_logs}
                                    reformulation_logs_exists={this.state.reformulation_logs_exists}
                                    component={"all_formulation"}
                                />
                            </Grid.Column>
                        </Grid.Row>}



                        <Grid.Row>
                            <Grid.Column>
                                <FormullaInfo
                                    number_of_months={this.state.number_of_months}
                                    is_prime={this.state.is_prime}
                                    admin_notes={this.state.admin_notes}
                                    operator_notes={this.state.operator_notes}
                                    Payment={Payment}
                                    payment_type={this.state.payment_type}
                                    date_created={this.state.date_created}
                                    date_made={this.state.date_made}
                                    updated_at={this.state.updated_at}
                                    is_completed={this.state.is_completed}
                                    username={this.state.username}
                                    refill_type={this.state.refill_type}
                                    disabledPermissionOnEdit={this.state.disabledPermissionOnEdit}
                                    reformulation_notes={this.state.reformulation_notes}
                                    onSubmitsupplement={this.onSubmitsupplement}
                                    onInputChange={this.onInputChange}
                                    createAll={this.createAll}
                                    qa_notes={this.state.qa_notes}
                                    qa_accepted_by={this.state.qa_accepted_by}
                                    qa_verified_at={this.state.qa_verified_at}
                                />
                            </Grid.Column>
                            <Grid.Row>
                                <Grid.Column>
                                    <div>
                                        {/* <div style={{ display: this.state.show ? 'block' : 'none' }}> */}
                                        <div>
                                            {
                                                this.state.blankArray && this.state.blankArray.map((elem, index) => {
                                                    return (
                                                        <div key={index} ref={(node) => { this.bodyRef.current[elem.data.formula_id] = node }}>
                                                            <MultiPdfDownload
                                                                downloadData={elem.data.calculation}
                                                                pre_names={elem.data.pre_names}
                                                                pro_names={elem.data.pro_names}
                                                                qc_weight={elem.data.qc_weight}
                                                                servings_per_container={elem.data.servings_per_container}
                                                                probiotic_blend={elem.data.probiotic_blend}
                                                                currentAge={elem.data.currentAge}
                                                                calories={elem.data.calories[0].total_value}
                                                                createPdf={this.createPdf}
                                                            />
                                                        </div>
                                                    )

                                                })
                                            }

                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </>
        );
    }
}

export default SampleFormullaIdTable;
